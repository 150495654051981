import React from 'react';
import { Col, Row } from 'antd';
import {Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide }   from 'swiper/react';


import 'swiper/swiper-bundle.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import ArrowRight from '../icons/ArrowRight';
import TestionalsImages12 from '../images/testimonals1.png';
import Star from '../images/start-85.png';

function Testimonails() {
   

    return (
        <div>
            <section className='testimonails'>
                <div className="container">
                    <div className="container">
                        <Row gutter={60} className="mt-5" align="middle" justify="center">
                            <Col span={12} className="mt-5" style={{ padding: 0 }}>
                                <Row gutter={[{xl:30},{xl:30}]}>
                                    <Col xl={12} xs={24}>
                                   
                                        <Swiper
                                            className='banner-slider'
                                            direction={'vertical'}
                                            spaceBetween={50}
                                            slidesPerView={2}
                                            centeredSlides={true}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                              }}    
                                            onSwiper={(swiper) => console.log(swiper)}
                                            onSlideChange={() => console.log('slide change')}
                                            modules={[Autoplay]}
                                        >
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>

                                    </Col>
                                    <Col xl={12} xs={24}>
                                        <Swiper
                                             className='banner-slider'
                                             direction={'vertical'}
                                             spaceBetween={50}
                                             slidesPerView={2}
                                            //  centeredSlides={true} 
                                             autoplay={{
                                                delay: 3500,
                                                disableOnInteraction: false,
                                              }}     
                                             onSwiper={(swiper) => console.log(swiper)}
                                             onSlideChange={() => console.log('slide change')}
                                             modules={[Autoplay]}
                                        >
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="card-0">
                                                    <div className="main-card">
                                                        <h4>Emily Roberts3</h4>
                                                        <p><span>Founder of Luxe Beauty</span></p>
                                                        <img src={TestionalsImages12} className='imag-fluid' alt="img" />
                                                    </div>
                                                    <p>From start to finish, the team at Creative Verse Studios was professional, responsive, and committed to delivering quality work. They transformed our website into a beautiful, user-friendly platform that has helped us connect with our customers in new ways.</p>
                                                    <div className="star">
                                                        <h5><span>4.8 /</span>5.0</h5>
                                                        <img src={Star} className='img-fluid' alt="img" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>

                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12} xl={10} xs={24}>
                                <div className="text-monails">
                                    <h4 className='theme-tagline-7'>Success and Satisfaction</h4>
                                    <h2 className="theme-h2"> <span className="highlight"> Real Stories </span>From  Our Clients <span className="highlight"></span></h2>
                                    <p className="theme-p-1">At Creative Verse Studios, we pride ourselves on building strong relationships with our clients and delivering exceptional results. Our testimonials section showcases authentic feedback from businesses and individuals who have experienced our services firsthand. Read on to hear their stories and learn how we've helped them achieve their goals, grow their businesses, and make a positive impact in their industries.</p>

                                    <Link className='theme-btn btn-outline' to='/'>View More <ArrowRight /></Link>
                                </div>

                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Testimonails;
