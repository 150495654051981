import React from 'react'
import { Col, Row } from 'antd';
import doodleImg from '../images/doodle-black.png'
import map from '../images/map1.png'
import ContactUs from '../components/ContactForm'

function Contact() {
  return (
    <div>
         <section className="Textheading holder-1 top-12">
                <div className="container">
                    <Row justify="center">
                        <Col span={18}>
                            <h4>Believe Our Services</h4>
                            <div className="text-portfolio-banner">
                                <h2>Have A ProjectIn Mind?</h2>
                                <span className='rotating-text'>
                                    <img className='doodle' src={doodleImg} alt="" />
                                    <ul>
                                        <li>Contact Us 
                                        </li>
                                        <li>Reach Us Out 
                                        </li>
                                        <li>  Get A Quote</li>
                                    </ul>
                                </span>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.</p>
                            </div>

                        </Col>
                    </Row>
                </div>
            </section>
            <section class="map">
                <div className="container">
                    <Row>
                        <Col span={24}>
                              <div className="build">
                                <img src={map}  className="img-fluid" alt="map" />
                                </div>                       
                        </Col>
                      
                    </Row>
                </div>
            </section>
            <ContactUs />
    </div>
  )
}

export default Contact
