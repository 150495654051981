import { Col, Row } from 'antd'
import React from 'react'
import ArrowRight from '../icons/ArrowRight'

const Newsletter = () => {
    return (
        <section className='newsletter-sec'>
            <div className="container">
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={18} xs={24}>
                        <div className="text-center">
                            <h6 className="theme-tagline">Subscribe to Our Newsletter</h6>
                            <h2 className="theme-h2">Never <span className="highlight">Miss</span> Any <br /> New <span className="highlight">Update</span></h2>
                            <p className="theme-p">Subscribe to our newsletter to receive the latest updates, industry news, and expert insights from Creative Verse Studios. Whether you're looking for digital trends, helpful tips, or exclusive offers, our newsletter delivers valuable content tailored to your interests. Join our community today and never miss a beat in the ever-evolving digital landscape."</p>
                            <div className="newsletter-field">
                                <input placeholder='Enter Your Email Address' type="email" name="email" required />
                                <button className="theme-btn btn-outline">Subscribe <ArrowRight /></button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Newsletter
