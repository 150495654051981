import { Col, Row } from 'antd'
import React from 'react'
import userCentricApproach from '../images/user-centric-approach.png'
import techIntegration from '../images/tech-integration.png'
import projectsMany from '../images/projects-many.png'
import { AiOutlineUser } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { TbMessage } from "react-icons/tb";
import { IoBriefcase } from "react-icons/io5";
import ArrowRight from '../icons/ArrowRight';
import ReviewsSlider from './ReviewsSlider'


const DigitalEcosystem = () => {
    return (
        <section className='digital-ecosystem'>
            <Row style={{ justifyContent: 'center' }}>
                <Col xl={16}>
                    <div className="text-center">
                        <h2 className="theme-h2">Digital Ecosystem</h2>
                        <p className="theme-p">Big Tech companies are already coming up with solutions to these challenges. They create digital ecosystems—seamless networks of services that add value to the core business, and boost revenue with cross-selling. </p>
                    </div>
                </Col>
            </Row>
            <div className="container">
                <div className="content-wrapper">
                    <Row style={{alignItems: 'stretch'}} gutter={[{ xl: 30 }, { xl: 30 }]}>
                        <Col xl={8}>
                            <div className="card-item">
                                <h4 className="theme-h5">User-Centric Approach</h4>
                                <p className="theme-p">We prioritize understanding the needs, behaviors, and expectations of your audience to create spaces that resonate.</p>
                                <img src={userCentricApproach} alt="" />
                            </div>
                            <div className="card-item">
                                <h4 className="theme-h5">Innovative Concepts</h4>
                                <p className="theme-p">Explore groundbreaking spatial design concepts. Our visionary designers are committed to delivering solutions that go beyond the ordinary, creating immersive digital experiences that captivate and inspire.</p>
                                <img className='fill-width' src={projectsMany} alt="" />
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className="card-item form-card">
                                <h4 className="theme-h5">Let's Start Building <br /> Together!</h4>
                                <p className="theme-p">Unlock Your Vision with Creative Verse Studios</p>
                                <form action="" method="post">
                                    <div className="form-elem">
                                        <div className="input-field">
                                            <AiOutlineUser className='field-icon' />
                                            <input type="text" placeholder='Enter Your Full Name' />
                                        </div>
                                        <div className="input-field">
                                            <CiMail className='field-icon' />
                                            <input type="text" placeholder='Enter Your Email Address' />
                                        </div>
                                        <div className="input-field">
                                            <FiPhone className='field-icon' />
                                            <input type="text" placeholder='Company Name (Optional)' />
                                        </div>
                                        <div className="input-field">
                                            <IoBriefcase className='field-icon' />
                                            <select name="" id="">
                                                <option disabled selected>Select Service</option>
                                                <option value='Logo Designing'>Logo Designing</option>
                                                <option value='Brand Identity'>Brand Identity</option>
                                                <option value='Website Desigining & Development'>Website Desigining & Development</option>
                                                <option value='Shopify Store Development'>Shopify Store Development</option>
                                                <option value='WordPress Website Development'>WordPress Website Development</option>
                                                <option value='Wix Website Development'>Wix Website Development</option>
                                                <option value='Custom Website Development'>Custom Website Development</option>
                                                <option value='Mobile Application Development'>Mobile Application Development</option>
                                                <option value='Search Engine Optimization (SEO)'>Search Engine Optimization (SEO)</option>
                                                <option value='Digital Marketing'>Digital Marketing</option>
                                                <option value='Video Editing'>Video Editing</option>
                                                <option value='2d/3d Animation'>2d/3d Animation</option>
                                                <option value='Content Writing'>Content Writing</option>
                                            </select>
                                        </div>
                                        <div className="input-field">
                                            <TbMessage className='field-icon' />
                                            <textarea placeholder='Project Description'></textarea>
                                        </div>
                                        <div className="input-field checkbox-field">
                                            <input type="checkbox" name="" id="agreement-checkbox" />
                                            <label className='theme-p' htmlFor="agreement-checkbox">I have read the privacy policy & terms and conditions</label>
                                        </div>
                                    </div>
                                    <button className="theme-btn btn-outline">Submit <ArrowRight /></button>
                                </form>
                            </div>
                        </Col>
                        <Col xl={8}>
                            <div className="card-item">
                                <h4 className="theme-h5">Transformative Success Stories</h4>
                                <p className="theme-p">From revitalizing brand identities to enhancing user engagement, these stories exemplify our commitment to delivering exceptional results.</p>
                                <ReviewsSlider />
                            </div>
                            <div className="card-item">
                                <h4 className="theme-h5">Technology Integration</h4>
                                <p className="theme-p">Our team seamlessly integrates the latest advancements to create digital spaces that redefine possibilities.</p>
                                <img  src={techIntegration} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    )
}

export default DigitalEcosystem
