import React from 'react'
import { Col, Row } from 'antd';
import check from '../images/checkbox.png'
import { IoIosChatbubbles } from "react-icons/io";
import { IoMail,IoCall  } from "react-icons/io5";

function ContactForm() {
    return (
        <div>
            <section className='Contact-form'>
                <div className="container">
                    <Row style={{alignItems: 'center'}} gutter={[{lg:30},{lg:30}]}>
                        <Col span="12">
                            <div className="contact-text">
                                <span><p>Contact Form</p></span>
                                <h2>Fill Out The Form & Get A Quote From Our Side</h2>
                            </div>

                            <div className="cutom-input">
                                <form action="">
                                    <div className="basic-input">

                                        <input placeholder='First Name' type="text" />

                                        <input placeholder='Last Name' type="text" />
                                    </div>
                                    <div className="basic-input">

                                        <input placeholder='williamsonhawkins@outlook.com' type="text" />

                                    </div>
                                    <div className="basic-input">
                                        <input placeholder='(760) 756-7568' type="text" />

                                    </div>
                                    <div className="basic-input">
                                        <textarea placeholder='Enter Your Comments Here ...' name="" id=""></textarea>

                                    </div>

                                    <div className="form-btn">
                                        <button type='submit'>Submit</button>
                                    </div>


                                </form>
                            </div>
                        </Col>
                        <Col span="12">
                            <img className='img-fluid' src={check} alt="" />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className='call-to-action'>
            <div className="container">
                <div className="cta-banner-content">
                    <ul>
                        <li>
                            <h5 className="theme-h5">Take A First Step</h5>
                            <h6 className="theme-h6">Towards Right Direction</h6>
                        </li>
                        <li>
                            <a href="/"><span className="icon"><IoIosChatbubbles/></span> Live
                                Chat</a>
                        </li>
                        <li>
                            <a href="tel:510-474-0516"><span className="icon"><IoCall/></span>
                                510-474-0516</a>
                        </li>
                        <li>
                            <a href="mailto:info@creativeversestudio.com"><span className="icon"><IoMail/></span>
                                info@creativeversestudio.com</a>
                        </li>
                        <li>
                            <button href="/" className="theme-btn btn-white">Contact Us</button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        </div>
    )
}

export default ContactForm
