import React from 'react'
import { Col, Row } from 'antd';
import PortfolioCard from '../components/PortfolioCard';
import doodleImg from '../images/doodle-black.png'
import images1 from '../images/Arrow1.png'
import images2 from '../images/image4.png'
import image3 from '../images/Rectangle4469.png'
import image4 from '../images/officer-chair.png'
import image5 from '../images/laptop-1.png'
import video from '../videos/abouts.mp4'
import graph from '../images/garphic.png'
import graph12 from '../images/group-1.png'
import Call from '../components/CallToAction'
import laptoop from '../images/laptop67.png'
import discoverComprehensive from '../images/discover-comprehensive.png'
import { Link } from 'react-router-dom';
import { FaArrowRight } from "react-icons/fa6";
import arrow from '../images/icon-1.png'



const TabItem = ({ data }) => {
    return (
        <div className='tabs-wrapper'>
            <Row gutter={50}>
                {data.map((item, index) => {
                    return (
                        <Col xl={12}>
                            <PortfolioCard key={index} data={item} />
                        </Col>
                    )
                })}
            </Row>
        </div>
    )
}

function Abouts() {

    return (
        <div>
            <section className="Textheading holder-1">
                <div className="container">
                    <Row justify="center">
                        <Col span={18}>
                            <h4>Elevate Your Digital Presence</h4>
                            <div className="text-portfolio-banner">
                                <h2>Crafting Exceptional Experiences for Your</h2>
                                <span className='rotating-text'>
                                    <img className='doodle' src={doodleImg} alt="" />
                                    <ul>
                                        <li>Audience
                                        </li>
                                        <li> Network
                                        </li>
                                        <li> Website</li>
                                    </ul>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="main-holy">
                <div className="container">
                    <Row style={{ alignItems: 'center' }}>
                        <Col span={12}>
                            <div className="about-p">
                                <span></span>
                                <p>Welcome to Creative Verse Studios, your trusted partner in digital innovation and success. Since 2000, we've been dedicated to delivering cutting-edge software solutions that empower businesses to thrive in the digital age.</p>
                            </div>
                        </Col>
                        <Col span={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                            <div className="action">
                                <span><img className='nation-frame' src={images2} alt="" /></span>
                                <img className='arrow' src={images1} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='texton'>
                <div className="container">
                    <Row style={{ alignItems: 'center' }}>
                        <Col span={15}>
                            <div className="mian-looper">
                                <h4 className="after-for">Our Mission</h4>
                                <h2>Empowering Businesses To Succeed In The Digital Age</h2>

                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="about-text">
                                <p>At Creative Verse Studios, our mission is simple yet powerful: to empower businesses to succeed in the digital age. We believe that technology has the power to transform industries, drive innovation, and create opportunities for growth and success.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='about-images'>
                <div className="container">
                    <Row gutter={50}>
                        <Col span={8}>
                            <div className="inner-images">
                                <img src={image3} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="inner-images">
                                <img src={image4} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="inner-images">
                                <img src={image5} className='img-fluid' alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className="about-video">
                <div className="container">
                    <video width="100%" loop autoPlay>
                        <source src={video} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </section>
            <section className='texton' style={{ padding: 0 }}>
                <div className="container">
                    <Row style={{ alignItems: 'center' }}>
                        <Col span={15}>
                            <div className="mian-looper">
                                <h4 className="after-for">Our Mission</h4>
                                <h2>Empowering Businesses To Succeed In The Digital Age</h2>

                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="about-text">
                                <p>At Creative Verse Studios, our mission is simple yet powerful: to empower businesses to succeed in the digital age. We believe that technology has the power to transform industries, drive innovation, and create opportunities for growth and success.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <section className='momentum'>
                <div className="container">
                    <div className="main-hoster">
                        <Row gutter={80} style={{ alignItems: 'center' }} >
                            <Col span={12}>
                                <span>
                                    <h5> Crafting Momentum</h5>
                                </span>
                                <h4>Building Brands in the Digital Realm Our Expertise, Your Success</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

                            </Col>
                            <Col span={12} style={{ justifyContent: 'end' }}>
                                <div className="black-content">
                                    <img src={graph} className='img-fluid' alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="main-hoster">
                        <Row gutter={80} style={{ alignItems: 'center' }} >
                            <Col span={12}>
                                <span>
                                    <h5> Connected Creativity</h5>
                                </span>
                                <h4>Building Brands in the Digital Realm Our Expertise, Your Success</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

                            </Col>
                            <Col span={12} style={{ justifyContent: 'end' }}>
                                <div className="black-content">
                                    <img src={graph12} className='img-fluid' alt="img" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
            <Call />
            <section className='story'>
                <div className="container">
                    <Row gutter={50}>
                        <Col span={10}>
                            <div className="story-1">
                                <h4 >Our Story</h4>
                                <h2>We Are The Pioneers In The Industry</h2>
                                <p>At Creative Verse Studios, we follow a structured and collaborative work process to ensure that every project is executed efficiently and delivers outstanding results. From the initial consultation to the final launch, we work closely with our clients every step of the way, keeping them informed and involved throughout the entire process.</p>
                            </div>
                        </Col>
                        <Col className='col-right' span={14}>
                            <div className="discoverComprehensive-img">
                                <div className="wrapper">
                                    <div className="content">
                                        <h4>Discover Our <br />
                                            Comprehensive Services</h4>
                                        <Link><FaArrowRight /></Link>
                                    </div>
                                    <img src={discoverComprehensive} alt="" />
                                </div>
                            </div>
                            <div className="story-12">
                                <img src={laptoop} className='img-fluid' alt="img" />
                            </div>

                        </Col>
                    </Row>
                </div>
            </section>
            <section className='cable12'>
                <div className="container">
                    <Row>
                        <Col span='24'>
                            <div className="news-letter">
                                <h4>SUBCRIBE OUR NEWSLETTER</h4>
                                <h2>Never Miss Any New Update</h2>
                                <p>Subscribe to our newsletter to receive the latest updates, industry news, and expert insights from Creative Verse Studios. Whether you're looking for digital trends, helpful tips, or exclusive offers, our newsletter delivers valuable content tailored to your interests. Join our community today and never miss a beat in the ever-evolving digital landscape."</p>
                                <form action="">
                                    <span><input type="text" placeholder='Enter Your Email Address' /></span>
                                    <span><button>Subscribe <img src={arrow} className='img-fluid' alt="img" /></button></span>
                                </form>
                            </div>
                        </Col>

                    </Row>
                </div>
            </section>

        </div>
    )
}

export default Abouts
