import React from 'react'
import InnerBanner from '../components/InnerBanner'
import { Col, Row } from 'antd';
import { FaArrowRight } from "react-icons/fa";
import Img1 from '../videos/pro2.mp4'
import Img2 from '../videos/pro1.mp4'
import Img3 from '../videos/pro3.mp4'
import Img4 from '../videos/pro4.mp4'
import Img5 from '../videos/pro7.mp4'
import Img6 from '../videos/pro6.mp4'
import Img7 from '../videos/pro5.mp4'
import Img8 from '../videos/pro8.mp4'
import Img9 from '../videos/pro9.mp4'
import Img10 from '../videos/pro10.mp4'
import Img11 from '../videos/pro12.mp4'
import Img12 from '../videos/pro13.mp4'
import Img13 from '../videos/pro14.mp4'
import ArrowRight from '../icons/ArrowRight';






const ServicesAll = () => {
  return (
    <>
      <InnerBanner tagline='Our Expertise' title='Comprehensive Solutions Tailored to Your' rotatingItems={['Brand', 'Stories', 'Business']} />

      <section className='services-sec'>
        <div className="container">
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">01. Brand Identity</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                  <video loop autoPlay muted>
                    <source src={Img2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">02. Logo Design</h5>
                  <p className='theme-p'>We understand that a logo is more than just a symbol; it’s the face of your brand. Our expert designers are dedicated to creating unique and memorable logos that encapsulate your brand’s essence and resonate with your target audience. We believe in the power of a well-designed logo to communicate your brand’s values, differentiate you from competitors, and leave a lasting impression.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">

                  <video loop autoPlay muted>
                    <source src={Img3} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">03. UI/UX Design</h5>
                  <p className='theme-p'>We understand that exceptional design is at the heart of a successful digital product. Our UI/UX design services are focused on creating intuitive and engaging user experiences that not only captivate your audience but also drive meaningful interactions. By combining cutting-edge design principles with user-centered strategies, we ensure that every touchpoint is optimized for both functionality and aesthetics.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img4} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">04. WordPress Development</h5>
                  <p className='theme-p'>We specialize in delivering top-notch WordPress development services tailored to meet the unique needs of your business. Our expert team of developers is proficient in creating custom, scalable, and user-friendly WordPress websites that not only look stunning but also perform seamlessly. Whether you're looking to build a new website from scratch or enhance your existing WordPress site, we provide comprehensive solutions that drive results.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>

                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">05. Shopify Development</h5>
                  <p className='theme-p'>We specialize in creating robust, scalable, and user-friendly Shopify stores tailored to your unique business needs. Whether you're a startup looking to make your mark or an established business aiming to enhance your online presence, our expert developers are here to help you succeed in the e-commerce space. Our team of experienced Shopify developers is well-versed in the latest e-commerce trends and technologies..</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img5} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">06. Custom Website Development</h5>
                  <p className='theme-p'>We specialize in creating custom websites that are as unique as your business. Our custom website development services are designed to provide you with a tailor-made digital solution that perfectly aligns with your brand identity and business objectives. We start by understanding your specific needs, goals, and target audience to ensure that every aspect of your website is optimized for success.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img7} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">07. Mobile Application Development</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img6} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">08. Social Media Marketing</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img8} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">09. WIX Development (WIX Studio, Velo)</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img9} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">10. Square Space Development</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img10} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">11.Seo (search engine optimization)</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img11} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">12. Video Animation</h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img12} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">13. Dropshipping Website </h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img13} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col span={24}>
              <div className="brand-45">
                <div className="parented">
                  <h5 className="theme-h5">14. Content Writing </h5>
                  <p className='theme-p'>We understand that a strong brand identity is the cornerstone of any successful business. Our comprehensive brand identity services are designed to help you create a distinctive and memorable brand that resonates with your target audience. From logo design and color schemes to typography and brand messaging, we meticulously craft every element to reflect your brand's unique personality and values.</p>
                  <button className='theme-btn btn-outline'>View <ArrowRight /></button>
                </div>
                <div className="parented-img">
                <video loop autoPlay muted>
                    <source src={Img13} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
        
        </div>

      </section>

    </>
  )
}

export default ServicesAll