import React from 'react'
import { Col } from 'antd'
// import purpleShade from '../images/purple-shade.jpg'
import { AiOutlineUser } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { CiMail } from "react-icons/ci";
import { TbMessage } from "react-icons/tb";

const BannerForm = () => {
    return (
        <Col xxl={20} style={{ marginRight: 'auto' }}>
            <div className="right-col">
                <div className="banner-form">
                    <div className="text-content">
                        <h4 className="theme-h4">Reach Us Out!</h4>
                        <p className="theme-p">Join hundreds of satisfied clients who've transformed their digital presence with us.</p>
                    </div>
                    <form action="">
                        <div className="form-elem">
                            <div className="input-field">
                                <AiOutlineUser className='field-icon' />
                                <input type="text" placeholder='Full Name' />
                            </div>
                            <div className="input-field">
                                <CiMail className='field-icon' />
                                <input type="text" placeholder='Email Address' />
                            </div>
                            <div className="input-field">
                                <FiPhone className='field-icon' />
                                <input type="text" placeholder='Phone Number' />
                            </div>
                            <div className="input-field">
                                <TbMessage className='field-icon' />
                                <textarea placeholder='Message'></textarea>
                            </div>
                            <button className="theme-btn btn-outline">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </Col>
    )
}

export default BannerForm
