import React from 'react'

const PricingCard = ({ data }) => {
    return (
        <div className='pricing-card'>
            <div>
            <div className="card-head">
                <div className="icon">
                    <img src={data.icon} alt={data.title} />
                </div>
                <ul>
                    <li>{data.subtitle}</li>
                    <li>{data.title}</li>
                </ul>
            </div>
            <div className="pricing">
                <h6 className="price">${data.salePrice}</h6>
                <h6 className="sale-price">/ <span>${data.price}</span></h6>
            </div>
            <p className="theme-p">{data.desc}</p>
            </div>        
            <h6 className="theme-h6">What's Included:</h6>
            <ul className="features">
                {data.features.map((feature, ind) => {
                    return <li key={ind}>{feature}</li>
                })}
            </ul>
            <button className="theme-btn btn-filled">Get Started Now</button>
        </div>
    )
}

export default PricingCard
