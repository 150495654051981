import React from 'react'
import { IoIosChatbubbles } from "react-icons/io";
import { IoMail,IoCall  } from "react-icons/io5";

const CallToAction = () => {
    return (
        <section className='call-to-action'>
            <div className="container">
                <div className="cta-banner-content">
                    <ul>
                        <li>
                            <h5 className="theme-h5">Take A First Step</h5>
                            <h6 className="theme-h6">Towards Right Direction</h6>
                        </li>
                        <li>
                            <a href="/"><span className="icon"><IoIosChatbubbles/></span> Live
                                Chat</a>
                        </li>
                        <li>
                            <a href="tel:510-474-0516"><span className="icon"><IoCall/></span>
                                510-474-0516</a>
                        </li>
                        <li>
                            <a href="mailto:info@creativeversestudio.com"><span className="icon"><IoMail/></span>
                                info@creativeversestudio.com</a>
                        </li>
                        <li>
                            <button href="/" className="theme-btn btn-white">Contact Us</button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default CallToAction
