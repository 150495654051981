import React from 'react'
import { Col, Row, Tabs } from 'antd';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import PortfolioCard from './PortfolioCard';
import video1 from '../videos/imgpsh_fullsize_anim.gif'
import video2 from '../videos/gif1.gif'
import video3 from '../videos/gif2.gif'
import Marquee from "react-fast-marquee";
import InnerBanner from './InnerBanner';


const TabItem = ({ data }) => {
    return (
        <div className='tabs-wrapper'>
            <Row gutter={[{ lg: 50 }, { lg: 40 }]}>
                {data.map((item, index) => {
                    return (
                        <Col xl={item.full ? 24 : 12}>
                            <PortfolioCard full={item.full} key={index} data={item} />
                        </Col>
                    )
                })}
            </Row>
            <div className="text-center load-more-btn">
            <button className="theme-btn btn-outline">Load More</button>
            </div>
        </div>
    )
}

function PortfolioBanner() {
    const brandIdentity = [
        {
            thumbnail: video1,
            title: 'MIZZLE BOY',
            shortDesc: 'Music Streaming Platform / Web App',
            link: '/'
        },
        {
            thumbnail: video2,
            title: 'RIVAS',
            shortDesc: 'Gaming Controllers Platform / Website',
            link: '/'
        },
        {
            thumbnail: video3,
            title: 'Ryde Lane',
            shortDesc: 'Cab Booking Application / Mobile Application',
            link: '/'
        },
        {
            thumbnail: video3,
            title: 'Energy Drink',
            shortDesc: 'E-Commerce Energy Drink Application / Mobile Application',
            link: '/'
        },
        {
            thumbnail: video3,
            full: true,
            title: 'Infinity Strandz',
            shortDesc: 'E-Commerce Hair Wig Platform / Website',
            link: '/'
        },

    ]
    const LogoDesign = [
        {
            thumbnail: '',
            title: 'Muzel',
            shortDesc: 'lorem Ipsum',
            link: '/'
        }
    ]
    const items = [
        {
            key: '1',
            label: <button className='theme-btn btn-outline'>Brand Identity</button>,
            children: <TabItem data={brandIdentity} />,
        },
        {
            key: '2',
            label: <button className='theme-btn btn-outline'>Logo Design</button>,
            children: <TabItem data={LogoDesign} />,
        },
    ];
    return (
        <>
        <InnerBanner tagline='Our Portfolio' title='Explore Our Showcase Of Successful' rotatingItems={['Brand','Stories','Business']} />
         
            <section>
                <Marquee loop={0}>
                    <ul className='shape-slider'>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                        <li className="shapes-slide"></li>
                    </ul>
                </Marquee>
            </section>

            <section className='portfolio'>
                <div className="container">
                    <Row className='row-elem'>
                        <Col xl={12} xs={24}>
                            <h6 className="theme-tagline">Elevate Your Digital Presence Today</h6>
                            <h2 className="theme-h2"><span className='highlight'>Unlock</span> Your <span className='highlight'>Potential</span> with <span className='highlight'>Us</span></h2>
                        </Col>
                        <Col xl={10} xs={24}>
                            <p className="theme-p">We are dedicated to helping you unlock your full potential in the digital realm. Our team of experts specializes in crafting bespoke solutions tailored to your unique needs. Whether you're looking to revamp your brand identity, develop a cutting-edge website, or launch a powerful marketing campaign, we're here to guide you every step of the way. With our passion for innovation and commitment to excellence, we'll work tirelessly to ensure your success in the digital landscape. Ready to take your business to new heights? Let's embark on this journey together.</p>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey="1" items={items} animated={{ tabPane: true }} centered />

                </div>
            </section>


        </>
    )
}

export default PortfolioBanner

