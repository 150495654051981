import { Col, Row } from 'antd'
import React from 'react'
import ArrowRight from '../icons/ArrowRight'

const DigitalServices = () => {
    const services = [
        'Logo Designing',
        'Brand Identity',
        'Website Desigining & Development',
        'Shopify Store Development',
        'WordPress Website Development',
        'Wix Website Development',
        'Custom Website Development',
        'Mobile Application Development',
        'Search Engine Optimization(SEO)',
        'Digital Marketing',
        'Video Editing',
        '2d / 3d Animation',
        'Content Writing'
    ]
    return (
        <section className='digital-services'>
            <div className="container">
                <Row className='row-wrapper'>
                    <Col xl={15} xs={24}>
                        <Col xl={20} xs={24}>
                        <h2 className="theme-h2">The <span className="highlight">Digital</span> Software <span className="highlight">Agency</span> That Makes A <span className="highlight">Difference</span></h2>
                        </Col>
                        <ul className='services-ul'>
                            {services.map((item, ind) => {
                                return <li key={ind}><button className="theme-btn btn-outline">{item}</button></li>
                            })}
                        </ul>
                    </Col>
                    <Col xl={7} xs={24}>
                        <div className="service-card">
                            <h5 className="theme-h5">Agency that build many amazing product to boost your business <br /> to upscale and upgrade your <br /> sales.</h5>
                        </div>
                        <button className="theme-btn btn-outline cta-btn">Get A Quote <ArrowRight /></button>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default DigitalServices
