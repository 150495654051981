import React from 'react'
import { Col, Row } from 'antd';
import doodleImg from '../images/doodle-black.png'

const InnerBanner = ({ tagline, title, rotatingItems }) => {
    return (
        <section className='Textheading'>
            <div className="container">
                <Row justify="center">
                    <Col span={18}>
                        <div className="text-center">
                            <h6 className='theme-tagline'>{tagline}</h6>
                            <div className="text-portfolio-banner">
                                <h2>{title}</h2>
                                <span className='rotating-text'>
                                    <img className='doodle' src={doodleImg} alt="" />
                                    {rotatingItems && <ul>
                                        {rotatingItems.map((item, ind) => <li key={ind}>{item}</li>)}
                                    </ul>}
                                </span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default InnerBanner
