import { Col, Row } from 'antd'
import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'

const ServiceTxtImg = ({ direction, image,video, tagline, title, paragraph, btns }) => {
    return (
        <section className='service-txt-img'>
            <div className="container">
                <Row className='txt-img-row' gutter={[{ lg: 40 }, { lg: 40 }]} style={{ flexDirection: direction == 'right' ? 'row-reverse' : 'row' }}>
                    <Col xl={12} xs={24}>
                        {image ? <img className='img-fluid w-100' src={image} alt={title} /> : <video className='img-fluid w-100' autoPlay loop muted>
                            <source src={video} />
                            </video>}
                    </Col>
                    <Col xl={12} xs={24}>
                        <h6 className="theme-tagline">{parse(tagline)}</h6>
                        <h3 className="theme-h3">{parse(title)}</h3>
                        <p className="theme-p">{parse(paragraph)}</p>
                        <ul className="btn-wrapper">
                            {btns.map((item, index) => {
                                return <li><Link key={index} to={item.link} className='theme-btn btn-outline'>{item.title} {btns.length - 1 == index && <ArrowRight />}</Link></li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ServiceTxtImg
