import React from 'react'
import Marquee from "react-fast-marquee";
import brand1 from '../images/brands/brand-logo-1.png'
import brand2 from '../images/brands/brand-logo-2.png'
import brand3 from '../images/brands/brand-logo-3.png'
import brand4 from '../images/brands/brand-logo-4.png'
import brand5 from '../images/brands/brand-logo-5.png'
import brand6 from '../images/brands/brand-logo-6.png'

const TrustedbyCompanies = () => {
    return (
        <>
            <section className="trusted-companies">
                <div className="text-center">
                    <h4 className="theme-h4">Trusted by 100+ Companies</h4>
                </div>
                <Marquee className='brands-slider' speed={30} autoFill>
                    <div className="brand-img">
                        <img src={brand1} alt="" />
                    </div>
                    <div className="brand-img">
                        <img src={brand2} alt="" />
                    </div>
                    <div className="brand-img">
                        <img src={brand3} alt="" />
                    </div>
                    <div className="brand-img">
                        <img src={brand4} alt="" />
                    </div>
                    <div className="brand-img">
                        <img src={brand5} alt="" />
                    </div>
                    <div className="brand-img">
                        <img src={brand6} alt="" />
                    </div>
                </Marquee>
            </section>
        </>
    )
}

export default TrustedbyCompanies
