import React from 'react'
import { Col, Row, Tabs } from 'antd';
import PricingCard from './PricingCard';
import pricingIcon1 from '../images/pricing-1.png'
import pricingIcon2 from '../images/pricing-2.png'
import pricingIcon3 from '../images/pricing-3.png'
import pricingIcon4 from '../images/pricing-4.png'
import pricingIcon5 from '../images/pricing-5.png'
import pricingIcon6 from '../images/pricing-6.png'

const PricingTabItem = ({ data }) => {
    return (
        <>
            <Row style={{ alignItems: 'stretch' }} gutter={[{ xl: 50 }, { xl: 50 }]}>
                {data.map((content, index) => {
                    return <Col xl={8} xs={24}><PricingCard key={index} data={content} /></Col>
                })}
            </Row>
        </>
    )
}

const TransparentPricing = ({content}) => {
    const brandIdentityPricing = [
        {
            icon: pricingIcon1,
            subtitle: 'For New Beginnings',
            title: 'Starter',
            desc: 'Ideal for startups and small businesses looking to establish a solid online presence. This plan offers essential features to get you started on your digital journey.',
            price: 99,
            salePrice: 49,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        },
        {
            icon: pricingIcon2,
            subtitle: 'A Step Up',
            title: 'Standard',
            desc: 'Designed for growing businesses seeking a balance of affordability and functionality. This plan provides more features to enhance your digital presence.',
            price: 199,
            salePrice: 99,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        },
        {
            icon: pricingIcon3,
            subtitle: 'Advanced Functionality',
            title: 'Professional',
            desc: 'Ideal for businesses that require advanced features and greater customization. This plan offers enhanced design and development services.',
            price: 399,
            salePrice: 199,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        },
        {
            icon: pricingIcon4,
            subtitle: 'Online Selling Made Easy',
            title: 'E-commerce',
            desc: 'Perfect for businesses looking to sell products online. This plan includes everything you need to set up and manage an e-commerce platform.',
            price: 599,
            salePrice: 299,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        },
        {
            icon: pricingIcon5,
            subtitle: 'Comprehensive Solutions',
            title: 'Business',
            desc: 'For established businesses seeking a complete digital solution. This plan provides a comprehensive suite of features and personalized services.',
            price: 999,
            salePrice: 499,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        },
        {
            icon: pricingIcon6,
            subtitle: 'Tailored for Large-Scale Needs',
            title: 'Enterprise',
            desc: 'For enterprises looking for the highest level of service and customization. This plan offers bespoke solutions to meet complex and large-scale needs.',
            price: 1999,
            salePrice: 999,
            features: [
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision',
                'Basic website design',
                'Up to 5 pages',
                'Mobile responsiveness',
                'Limited support',
                '1 design revision'
            ]
        }
    ]
    const items = [
        {
            key: '1',
            label: <button className='theme-btn btn-outline'>Brand Identity</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '2',
            label: <button className='theme-btn btn-outline'>Logo Design</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '3',
            label: <button className='theme-btn btn-outline'>UI/UX Design</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '4',
            label: <button className='theme-btn btn-outline'>WordPress Development</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '5',
            label: <button className='theme-btn btn-outline'>Shopify Development</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '6',
            label: <button className='theme-btn btn-outline'>Custom Website Development</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
        {
            key: '7',
            label: <button className='theme-btn btn-outline'>Mobile Application Development</button>,
            children: <PricingTabItem data={brandIdentityPricing} />,
        },
    ];
    return (
        <section className='pricing-sec'>
            <div className="container">
                <Row style={{ justifyContent: 'center' }}>
                    <Col xl={18}>
                        <div className="text-center">
                            <h6 className="theme-tagline">Transparent Pricings</h6>
                            <h2 className="theme-h2"><span className="highlight">Flexible</span> Plans <span className="highlight-2">Tailored</span> <br /> To Your <span className="highlight">Needs</span></h2>
                            <p className="theme-p">At Creaup or an established enterprise, we offer flexible plans that cater to a range of budgets and requirements. Our goal is to provide exceptional value without compromising on quality. Explore our pricing options to find the perfect fit for your needs and start your journey with us today.</p>
                        </div>
                    </Col>
                </Row>
                {!content ? <Tabs defaultActiveKey="1" items={items} animated={{ tabPane: true }} centered /> : <div class="single-pricing">
                <PricingTabItem data={brandIdentityPricing} /></div>}
            </div>
        </section>
    )
}

export default TransparentPricing
