import React from 'react'

function Contentwritting() {
  return (
    <div>
      Contentwritting
    </div>
  )
}

export default Contentwritting
