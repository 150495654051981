import React from 'react'
import { Col, Row } from 'antd'
import doodleImg from '../images/doodle-black.png'
// import purpleShade from '../images/purple-shade.jpg'
import { Link } from 'react-router-dom';
import satisfiedClient from '../images/satisfied-clients.png'
import ArrowRight from '../icons/ArrowRight';
import BannerForm from './BannerForm';

const HomeBanner = () => {
    return (
        <section className="home-banner">
            {/* <img className='shade-1' src={purpleShade} alt="" />
            <img className='shade-2' src={purpleShade} alt="" />
            <img className='shade-3' src={purpleShade} alt="" /> */}
            <div className="container">
                <Row className='home-banner-row'>
                    <Col xxl={12} xl={14}>
                        <div className="left-col">
                            <h6 className="theme-tagline">Ignite Your Creativity</h6>
                            <h1 className="theme-h1">Where <span className='rotating-text'>
                                <img className={'doodle'} src={doodleImg} alt="" />
                                <ul>
                                    <li>Creativity</li>
                                    <li>Innovation</li>
                                    <li>Efficiency</li>
                                </ul>
                            </span> Knows No Bounds</h1>
                            <p className="theme-p">Welcome to Creative Verse Studios, where boundless imagination meets limitless possibilities. Here, we invite you to embark on an inspiring journey of innovation and exploration. Join us as we push the boundaries of innovation, challenge the status quo, and dare to dream bigger. Together, let's craft a future where every idea has the power to shape our world.</p>
                            <ul className="banner-footer">
                                <li>
                                    <Link className="theme-btn btn-outline" to='/'>Get Started <ArrowRight /></Link>
                                </li>
                                <li>
                                    <img className='satisfied-clients' src={satisfiedClient} alt="" />
                                </li>
                                <li>
                                    <span className="numbers">1000+</span>
                                    <span className="text">Satisfied Clients</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={9} xs={12}>
                        <BannerForm />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default HomeBanner
