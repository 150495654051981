import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/square-space/1.png'
import icon2 from '../images/service-features-icons/square-space/2.png'
import icon3 from '../images/service-features-icons/square-space/3.png'
import icon4 from '../images/service-features-icons/square-space/4.png'
import icon5 from '../images/service-features-icons/square-space/5.png'
import featuredVideo from '../videos/pro10.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function SquareSpace() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Responsive Design',
      paragraph: 'Ensuring your website looks and functions beautifully on all devices.'
    },
    {
      icon: icon2,
      title: 'E-commerce',
      paragraph: 'Developing robust online stores with secure payment gateways.'
    },
    {
      icon: icon3,
      title: 'CMS',
      paragraph: 'Providing easy-to-use CMS for effortless content updates.'
    },
    {
      icon: icon4,
      title: 'SEO Optimization',
      paragraph: 'Incorporating best practices to improve your search engine ranking.'
    },
    {
      icon: icon5,
      title: 'Custom Functionality',
      paragraph: 'Developing unique features tailored to your specific needs.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline="Crafting Stunning and Functional Websites with Square Space" title='Square Space <span class="highlight">Development</span>' paragraph="In today’s digital landscape, having a visually appealing and highly functional website is crucial for any business. At Creative Studios, we specialize in developing beautiful, user-friendly websites using Square Space. Our team of experts is dedicated to bringing your vision to life, ensuring your online presence stands out and effectively engages your audience." />
      <ServiceFeatures tagline='Custom Websites Built to Reflect Your Brand' title='Tailored <span class="highlight">Square Space</span><br /> Solutions' paragraph='We focus on incorporating key features that make your Square Space website both visually appealing and highly functional.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Designed for Performance and Aesthetics' title='<span class="highlight">Features</span> That <span class="highlight">Enhance</span> Your <span class="highlight">Online Presence</span>' paragraph="Our approach to Square Space development is centered around understanding your unique business needs and goals. We start by getting to know your brand, your audience, and your objectives. With this insight, we craft customized Square Space websites that not only look stunning but also provide seamless user experiences. Our goal is to create a website that truly represents your brand and drives business success. Our team of experienced Square Space developers brings a wealth of knowledge and creativity to every project. We stay updated with the latest Square Space features and trends to ensure your website is at the cutting edge of design and functionality." btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default SquareSpace
