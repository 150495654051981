import React from 'react'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import icon1 from '../images/service-features-icons/logo-design/1.png'
import icon2 from '../images/service-features-icons/logo-design/2.png'
import icon3 from '../images/service-features-icons/logo-design/3.png'
import icon4 from '../images/service-features-icons/logo-design/4.png'
import featuredVideo from '../videos/logo-design-featured.mp4'
import ServiceTxtImg from '../components/ServiceTxtImg'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function LogoDesign() {
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Custom Typography',
      paragraph: 'Crafting unique letterforms that complement your logo design.'
    },
    {
      icon: icon2,
      title: 'Color Theory',
      paragraph: 'Selecting a color palette that conveys the right emotions and aligns with your brand.'
    },
    {
      icon: icon3,
      title: 'Symbolism',
      paragraph: 'Incorporating meaningful symbols and icons that enhance your brand’s story.'
    },
    {
      icon: icon4,
      title: 'Versatility',
      paragraph: 'Designing logos that look great in various sizes and formats, ensuring consistency across all applications.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Crafting Iconic Logos That Capture Your Brand Essence' title='Logo <span class="highlight">Design</span>' paragraph='A logo is more than just a visual mark—it’s the heart of your brand identity. At Creative Verse Studios, we specialize in creating unique, memorable logos that encapsulate your brand’s values and personality. Our expert designers work closely with you to develop a logo that not only stands out but also resonates with your target audience, leaving a lasting impression.' />
      <ServiceFeatures tagline='From Concept to Creation' title='Tailored <span class="highlight">Logo Design</span> Process' paragraph='A great logo is built on several key elements that work together to create a strong, cohesive brand mark.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' video={featuredVideo} tagline='Skilled Logo Designers' title='Turning <span class="highlight">Ideas</span> into <span class="highlight">Timeless</span> Logos' paragraph='Our team of skilled logo designers combines artistic talent with strategic insight to create logos that are both beautiful and effective. With a deep understanding of design principles and branding trends, we craft logos that are not only visually appealing but also timeless and versatile. Whether you’re a startup or an established business, we’re here to help you create a logo that captures the essence of your brand. We’re proud of the logos we’ve designed for a wide range of clients across various industries. Our portfolio showcases our ability to create distinctive, impactful logos that help businesses establish a strong brand presence. Your logo is the face of your brand—make it unforgettable.' btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default LogoDesign
