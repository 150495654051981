import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'

const BlogsCard = ({ data }) => {
    return (
        <div className='blog-card'>
            <Link className="card-head" to={data.link}>
                <h4 className="theme-h4">{data.title}</h4>
                <span className='icon'><ArrowRight /></span>
            </Link>
            <p className="theme-p">{data.desc}</p>
            <div className="img-wrapper">
                <span className="cate theme-btn btn-filled">{data.cate}</span>
                <img className='img-fluid w-100' src={data.featuredImg} alt={data.title} />
            </div>
        </div>
    )
}

export default BlogsCard
