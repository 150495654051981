import React from 'react'
import creatingExperiencesVideo from '../videos/creating-experiences.mp4'

const CreatingExperience = () => {
  return (
    <section className='creating-experience'>
      <div className="container">
        <div className="text-center">
            <h2 className="theme-h2"><span className="highlight">Creating</span> Experiences <br /> Through Our <span className="highlight-2">Creativity</span></h2>
        </div>
        <div className="video-wrapper">
        <video className='w-100' loop autoPlay muted>
            <source src={creatingExperiencesVideo} />
        </video>
        </div>
      </div>
    </section>
  )
}

export default CreatingExperience
