import { Col, Row } from 'antd'
import React from 'react'
import BlogsCard from './BlogsCard'
import blogImg1 from '../images/blog-1.png'
import blogImg2 from '../images/blog-2.png'
import blogImg3 from '../images/blog-3.png'
import blogImg4 from '../images/blog-4.png'
import ArrowRight from '../icons/ArrowRight'

const BlogsWrapper = () => {
    return (
        <section className='blogs-section'>
            <div className="container">
                <Row className='row-elem'>
                    <Col xl={12} xs={24}>
                        <h6 className="theme-tagline">Our Blogs</h6>
                        <h2 className="theme-h2">Stay <span className="highlight">Updated</span> With The Latest <span className="highlight">Trends</span></h2>
                    </Col>
                    <Col xl={10} xs={24}>
                        <p className="theme-p">Welcome to our blog, your go-to source for the latest insights and inspiration in the digital world. Here, we share expert tips, industry trends, and thought-provoking content to help you stay ahead in your field. Whether you're looking for practical advice or creative ideas, our blog is designed to inform and inspire you as you navigate the ever-changing digital landscape.</p>
                    </Col>
                </Row>
                <div className="blogs-listing">
                    <Row gutter={[{ xl: 40 }, { xl: 40 }]}>
                        <Col xl={12}>
                            <BlogsCard data={{
                                title: 'The Future of Ecommerce',
                                desc: 'E-commerce is evolving rapidly, and staying ahead of the curve is essential for success. In this post, we explore the latest trends shaping the future of online shopping, from AI-driven personalization to the rise of social commerce.',
                                cate: 'trends',
                                link: '/',
                                featuredImg: blogImg1
                            }} />
                        </Col>
                        <Col xl={12}>
                            <BlogsCard data={{
                                title: 'Maximizing Users',
                                desc: 'Microinteractions are subtle animations or design elements that enhance user experience and engagement. Discover how incorporating these small details into your website or app can make a big impact on user satisfaction.',
                                cate: 'engagement',
                                link: '/',
                                featuredImg: blogImg2
                            }} />
                        </Col>
                        <Col xl={12}>
                            <BlogsCard data={{
                                title: 'Mobile Optimization',
                                desc: 'With more users accessing websites on their mobile devices, optimizing for mobile has never been more critical. Learn why responsive design and mobile-first strategies are essential for delivering an exceptional user experience.',
                                cate: 'designs',
                                link: '/',
                                featuredImg: blogImg3
                            }} />
                        </Col>
                        <Col xl={12}>
                            <BlogsCard data={{
                                title: 'Improving Website reach',
                                desc: `Improving your website's search engine optimization (SEO) can drive more organic traffic and boost visibility. In this post, we share effective strategies to enhance your site's SEO, from keyword research to quality content creation.`,
                                cate: 'SEO',
                                link: '/',
                                featuredImg: blogImg4
                            }} />
                        </Col>
                    </Row>
                    <div className="btn-wrapper">
                        <button className="theme-btn btn-outline">View More <div className="icon"><ArrowRight /></div></button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogsWrapper
