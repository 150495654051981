import React from 'react'

const ArrowRight = (props) => {
    return (
        <svg {...props} width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 0.5C16 1.242 16.733 2.35 17.475 3.28C18.429 4.48 19.569 5.527 20.876 6.326C21.856 6.925 23.044 7.5 24 7.5M24 7.5C23.044 7.5 21.855 8.075 20.876 8.674C19.569 9.474 18.429 10.521 17.475 11.719C16.733 12.65 16 13.76 16 14.5M24 7.5L0 7.5" strokeWidth="2" />
        </svg>
    )
}

export default ArrowRight
