import { Col, Row } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import featuredCompleteIdea from '../images/complete-idea.png'
import ArrowRight from '../icons/ArrowRight'

const CompleteIdeas = () => {
    return (
        <section className='complete-ideas'>
            <div className="container">
                <Row className='complete-deas-row'>
                    <Col xxl={16} xl={20} xs={24}>
                        <h2 className="theme-h2">
                            <span>
                            We Complete
                            <Link className='talk-link'>
                                <span className="icon"><ArrowRight color='#000' /></span>
                                let's talk now
                            </Link>
                            </span>
                            <span>Your <span className="highlight">Creative</span> Ideas</span>
                        </h2>
                        <Col style={{marginLeft: 'auto'}} xl={17}>
                        <p className="theme-p">Dive deep into the depths of your creativity as you navigate through a world of endless opportunities. Whether you're a seasoned visionary or just beginning to uncover your creative spark, our platform is designed to fuel your passion and ignite your imagination.</p>
                        </Col>
                    </Col>
                </Row>
                <img src={featuredCompleteIdea} alt="We Complete Your Creative Ideas" className="featured-img" />
            </div>
        </section>
    )
}

export default CompleteIdeas
