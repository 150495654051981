import React from 'react'
import HomeBanner from '../components/HomeBanner'
import TrustedbyCompanies from '../components/TrustedbyCompanies'
import CompleteIdeas from '../components/CompleteIdeas'
import ComprehensiveDigitalSolution from '../components/ComprehensiveDigitalSolution'
import CreatingExperience from '../components/CreatingExperience'
import AnimatedStrips from '../components/AnimatedStrips'
import Portfolios from '../components/Portfolios'
import TransparentPricing from '../components/TransparentPricing'
import FutureCreativity from '../components/FutureCreativity'
import CallToAction from '../components/CallToAction'
import DigitalEcosystem from '../components/DigitalEcosystem'
import DigitalServices from '../components/DigitalServices'
import ExceedExpectations from '../components/ExceedExpectations'
import VisionaryAgency from '../components/VisionaryAgency'
import BlogsWrapper from '../components/BlogsWrapper'
import Newsletter from '../components/Newsletter'
import FrequentlyAskedQuestions from '../components/FrequentlyAskedQuestions'
import Testimonails from '../components/Testimonails'

<components />

const Home = () => {
  return (
    <>
      <HomeBanner />
      <TrustedbyCompanies />
      <CompleteIdeas />
      <ComprehensiveDigitalSolution />
      <CreatingExperience />
      <AnimatedStrips />
      <Portfolios />
      <TransparentPricing />
      <FutureCreativity />
      <CallToAction />
      <DigitalEcosystem />
      <DigitalServices />
      <ExceedExpectations />
      <VisionaryAgency />
      <BlogsWrapper />
      <Newsletter />
      <FrequentlyAskedQuestions />
      <Testimonails />

    </>
  )
}

export default Home
