import { Col, Row } from 'antd'
import React from 'react'
import rydelaneThumbnail from '../images/projects/rydelane.png'
import rivasThumbnail from '../images/projects/rivas.png'
import usdoorsdirectThumbnail from '../images/projects/usdoorsdirect.png'
import infinityStandardsThumbnail from '../images/projects/infinity-standards.png'
import arrowUpGradient from '../images/arrow-up.png'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'

const Portfolios = () => {
    const portfolioItems = [
        {
            title: 'Rydelane',
            shortHeading: 'Building A Cab Booking App For Going & Coming From Airport',
            category: 'Taxi Booking App',
            thumbnail: rydelaneThumbnail,
            additional: [
                {
                    text: 'Sales conversion in the iOS app',
                    numbers: '+14%'
                },
                {
                    text: 'Sales conversion in the Android app',
                    numbers: '+10%'
                },
                {
                    text: 'Average target increase',
                    numbers: '+15%'
                }
            ]
        },
        {
            title: 'RIVAS',
            shortHeading: 'E-Commerce Website For Gaming Controller’s Skins',
            category: 'E-Commerce',
            thumbnail: rivasThumbnail
        },
        {
            title: 'US DOORS',
            shortHeading: 'Mobile Application For Automatic Doors & Shutters',
            category: 'Mobile App',
            thumbnail: usdoorsdirectThumbnail
        },
        {
            title: 'INFINITY STRANDZ',
            shortHeading: 'Building A Hair Extensions Selling Platform ',
            category: 'E-Commerce Website',
            thumbnail: infinityStandardsThumbnail,
            additional: [
                {
                    text: 'Sales conversion in the iOS app',
                    numbers: '+14%'
                },
                {
                    text: 'Sales conversion in the Android app',
                    numbers: '+10%'
                },
                {
                    text: 'Average target increase',
                    numbers: '+15%'
                }
            ]
        }
    ]
    return (
        <section className='portfolio-sec'>
            <div className="container">
                <Row gutter={[{ xxl: 100,xl: 50 }, { xxl: 100,xl:50 }]}>
                    {portfolioItems.map((item, ind) => {
                        return (
                            <Col key={ind} xl={(item.additional && item.additional.length > 0) ? 24 : 12} xs={24}>
                                <div className={(item.additional && item.additional.length > 0) ? 'portfolio-card card-full' : 'portfolio-card'}>
                                    <Row className='portfolio-item-row'>
                                        <Col xl={(item.additional && item.additional.length > 0) ? 16 : 24} xs={24}>
                                            <div className="port-img">
                                                <h4 className="project-name text-outline">{item.title}</h4>
                                                <img className='img-fluid' src={item.thumbnail} alt={item.title + ' thumbnail'} />
                                            </div>
                                        </Col>
                                        <Col xl={(item.additional && item.additional.length > 0) ? 8 : 24} xs={24}>
                                            <div className="port-text">
                                                <div>
                                                    <h6 className="theme-h6">{item.category}</h6>
                                                    <h4 className="theme-h4">{item.shortHeading}</h4>
                                                    {(item.additional && item.additional.length > 0) ? (
                                                        <ul className="additional-items">
                                                            {item.additional.map((item, index) => {
                                                                return <li key={index}>
                                                                    <h4 className="additional-number">{item.numbers} <span className="icon"><img src={arrowUpGradient} alt='arrow up' /></span></h4>
                                                                    <h6 className="additional-text">{item.text}</h6>
                                                                </li>
                                                            })}
                                                        </ul>) :
                                                        <></>
                                                    }
                                                </div>
                                                <Link className="theme-btn btn-filled" to='/'>View Portfolio <ArrowRight /></Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </div>
        </section>
    )
}

export default Portfolios
