import React from 'react'
import Banner from '../components/PortfolioBanner'




function Portfolio() {
  return (
    <>
    <Banner />
    </>
  )
}

export default Portfolio