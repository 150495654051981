import React from 'react'
import Marquee from "react-fast-marquee";

const AnimatedStrips = () => {
    return (
        <section className='strips-wrapper'>
            <Marquee className='animated-strip strip-1' speed={30} autoFill direction='right'>
                <ul>
                    <li>Brand Identity</li>
                    <li>Logo Design</li>
                    <li>UI/UX Design</li>
                    <li>WordPress Development</li>
                    <li>Shopify Development</li>
                    <li>Custom Website Development</li>
                    <li>Mobile Application Development</li>
                    <li>Brand Identity</li>
                    <li>Logo Design</li>
                    <li>UI/UX Design</li>
                    <li>WordPress Development</li>
                    <li>Shopify Development</li>
                    <li>Custom Website Development</li>
                    <li>Mobile Application Development</li>
                </ul>
            </Marquee>
            <Marquee className='animated-strip strip-2' speed={30} autoFill direction='left'>
                <ul>
                    <li>Brand Identity</li>
                    <li>Logo Design</li>
                    <li>UI/UX Design</li>
                    <li>WordPress Development</li>
                    <li>Shopify Development</li>
                    <li>Custom Website Development</li>
                    <li>Mobile Application Development</li>
                    <li>Brand Identity</li>
                    <li>Logo Design</li>
                    <li>UI/UX Design</li>
                    <li>WordPress Development</li>
                    <li>Shopify Development</li>
                    <li>Custom Website Development</li>
                    <li>Mobile Application Development</li>
                </ul>
            </Marquee>
        </section>
    )
}

export default AnimatedStrips
