import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import { Autoplay, EffectCards } from 'swiper/modules';
import clientAvatar1 from '../images/client.png'

const ReviewsSlider = () => {
    return (
        <div className='reviews-slider'>
            <Swiper
                effect={'cards'}
                grabCursor={true}
                loop={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[EffectCards, Autoplay]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <p className="theme-p">“Great Brand Development, personally loved it due to User-Centric Approach”</p>
                    <div className="client-info">
                        <img src={clientAvatar1} alt="" />
                        <ul>
                            <li>Kung Woo</li>
                            <li>Founder & CEO, MuseTech</li>
                        </ul>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="theme-p">“Great Brand Development, personally loved it due to User-Centric Approach”</p>
                    <div className="client-info">
                        <img src={clientAvatar1} alt="" />
                        <ul>
                            <li>Kung Woo</li>
                            <li>Founder & CEO, MuseTech</li>
                        </ul>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="theme-p">“Great Brand Development, personally loved it due to User-Centric Approach”</p>
                    <div className="client-info">
                        <img src={clientAvatar1} alt="" />
                        <ul>
                            <li>Kung Woo</li>
                            <li>Founder & CEO, MuseTech</li>
                        </ul>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <p className="theme-p">“Great Brand Development, personally loved it due to User-Centric Approach”</p>
                    <div className="client-info">
                        <img src={clientAvatar1} alt="" />
                        <ul>
                            <li>Kung Woo</li>
                            <li>Founder & CEO, MuseTech</li>
                        </ul>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default ReviewsSlider
