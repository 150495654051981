import React from 'react'
import { Row, Col } from 'antd'
import ServiceBanner from '../components/ServiceBanner'
import ServiceFeatures from '../components/ServiceFeatures'
import PortfolioCard from '../components/PortfolioCard';
import video1 from '../videos/imgpsh_fullsize_anim.gif'
import video2 from '../videos/gif1.gif'
import video3 from '../videos/gif2.gif'
import icon1 from '../images/service-features-icons/brand-identity/1.png'
import icon2 from '../images/service-features-icons/brand-identity/2.png'
import icon3 from '../images/service-features-icons/brand-identity/3.png'
import icon4 from '../images/service-features-icons/brand-identity/4.png'
import ServiceTxtImg from '../components/ServiceTxtImg'
import haystack from '../images/haystack.png'
import TransparentPricing from '../components/TransparentPricing';
import ContactForm from '../components/ContactForm';

function Brnadidentity() {
  const brandIdentity = [
    {
      thumbnail: video1,
      title: 'MIZZLE BOY',
      shortDesc: 'Music Streaming Platform / Web App',
      link: '/'
    },
    {
      thumbnail: video2,
      title: 'RIVAS',
      shortDesc: 'Gaming Controllers Platform / Website',
      link: '/'
    },
    {
      thumbnail: video3,
      title: 'Ryde Lane',
      shortDesc: 'Cab Booking Application / Mobile Application',
      link: '/'
    },
    {
      thumbnail: video3,
      title: 'Energy Drink',
      shortDesc: 'E-Commerce Energy Drink Application / Mobile Application',
      link: '/'
    },
    {
      thumbnail: video3,
      // full: true,
      title: 'Infinity Strandz',
      shortDesc: 'E-Commerce Hair Wig Platform / Website',
      link: '/'
    },

  ]
  const serviceFeatures = [
    {
      icon: icon1,
      title: 'Typography',
      paragraph: 'Selecting fonts that convey your brand’s personality and message.'
    },
    {
      icon: icon2,
      title: 'Color Palette',
      paragraph: 'Developing a color scheme that evokes the right emotions and associations.'
    },
    {
      icon: icon3,
      title: 'Visual Elements',
      paragraph: 'Establishing clear guidelines to ensure consistency across all touchpoints.'
    },
    {
      icon: icon4,
      title: 'Brand Guidelines',
      paragraph: 'Selecting fonts that convey your brand’s personality and message.'
    },
  ]
  return (
    <>
      <ServiceBanner tagline='Crafting Unique and Memorable Brand Identities' title='Brand <span class="highlight">Identity</span>' paragraph='Your brand identity is the cornerstone of your business, reflecting your values, mission, and personality to the world. At Creative Verse Studios, we specialize in creating cohesive and compelling brand identities that resonate with your target audience and set you apart from the competition. From logo design to comprehensive branding strategies, we ensure your brand tells a story that captivates and inspires.' />
      <ServiceFeatures tagline='Every Element Matters' title='Comprehensive <br /> <span class="highlight">Branding</span> Solutions' paragraph='A strong brand identity is built on multiple elements working harmoniously together.' features={serviceFeatures} />
      <ServiceTxtImg direction='left' image={haystack} tagline='Transforming Ideas into Iconic Brands' title='Experienced <span class="highlight">Branding</span> Professionals' paragraph='Our team of experienced branding professionals is dedicated to bringing your vision to life. With a keen eye for design and a deep understanding of market trends, we create brand identities that are not only visually stunning but also strategically sound. From startups to established businesses, we have the expertise to develop brand identities that make a lasting impact. Your brand deserves to shine. Contact us today to discuss your brand identity needs and learn how we can help you create a compelling brand that captivates your audience and drives your business forward. Let’s work together to build a brand identity that truly reflects who you are and what you stand for.' btns={[{ title: 'View Pricing', link: '/' }, { title: 'Contact Us', link: '/' }]} />
      <section className='portfolio'>
        <div className="container">
          <Row className='row-elem' gutter={[{lg: 30},{lg: 30}]}>
            <Col xl={12} xs={24}>
              <h6 className="theme-tagline">Our Brand Identity Portfolio</h6>
              <h3 className="theme-h3"><span className="highlight">Showcasing</span> Our Creative <span className="highlight">Excellence</span></h3>
            </Col>
            <Col xl={10} xs={24}>
              <p className="theme-p">Our brand identity portfolio is a testament to our passion for creativity and our commitment to excellence. Here, we present a selection of our most impactful branding projects, each designed to help our clients stand out in their respective markets. From innovative logo designs to comprehensive branding strategies, these case studies highlight our ability to transform businesses through powerful visual storytelling. Explore our portfolio to see how we bring brands to life, ensuring they resonate with their audience and leave a lasting impression.</p>
            </Col>
          </Row>
          <Row gutter={[{ lg: 50 }, { lg: 40 }]}>
            {brandIdentity.map((item, index) => {
              return (
                <Col xl={item.full ? 24 : 12}>
                  <PortfolioCard full={item.full} key={index} data={item} />
                </Col>
              )
            })}
          </Row>

        </div>
      </section>
      <TransparentPricing content='brandIdentityPricing' />
      <ContactForm />
    </>
  )
}

export default Brnadidentity
