import { Col, Row } from 'antd'
import React from 'react'
import parse from 'html-react-parser'
import doodleImg from '../images/doodle-black.png'
import { Link } from 'react-router-dom'
import ArrowRight from '../icons/ArrowRight'
import BannerForm from './BannerForm'

const ServiceBanner = ({ tagline, title, paragraph }) => {
    return (
        <section className='service-banner'>
            <div className="container">
                <Row className='banner-row'>
                    <Col xl={12} xs={24}>
                        <div className="banner-text">
                            <h6 className="theme-tagline">{tagline}</h6>
                            <h3 className="theme-h3">{parse(title)}
                                <img className={'doodle'} src={doodleImg} alt="" />
                            </h3>
                            <p className="theme-p">{paragraph}</p>
                            <ul className="btn-wrapper">
                                <li>
                                    <Link className='theme-btn btn-outline'>View Pricing</Link>
                                </li>
                                <li>
                                    <Link className='theme-btn btn-outline'>Get Started <ArrowRight /></Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={9} xs={12}>
                        <BannerForm />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ServiceBanner
