import React from 'react'
import ArrowRight from '../icons/ArrowRight';



function PortfolioCard({ data, full }) {
    return (
        // <div className="container">
            <div className={`portfolio-card ${full? 'full':''}`}>
                <img class="full-width-media" src={data.thumbnail} />
                <div className="main-card">
                    <div className="custom-board">
                        <h4 className="theme-h">{data.title}</h4>
                        <p>{data.shortDesc}</p>
                    </div>
                    <a href={data.link}><ArrowRight /></a>
                </div>
            </div>
        // </div>
    )
}

export default PortfolioCard
