import React from 'react'

const ArrowDownIcon = (props) => {
    return (
        <>
            <svg {...props} width="50" height="30" viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M50 1.00098C47.35 1.00098 43.3929 3.56648 40.0714 6.16348C35.7857 9.50248 32.0464 13.4925 29.1929 18.067C27.0536 21.497 25 25.655 25 29.001M25 29.001C25 25.655 22.9464 21.4935 20.8071 18.067C17.95 13.4925 14.2107 9.50248 9.93214 6.16348C6.60714 3.56648 2.64286 1.00098 0 1.00098M25 29.001L24.8351 27.251" strokeWidth="2" />
            </svg>
        </>
    )
}

export default ArrowDownIcon
