import { Col, Row, Collapse } from 'antd'
import React from 'react'
import ArrowDownIcon from '../icons/ArrowDown'
import ArrowRight from '../icons/ArrowRight'

const CollapseChild = ({ btns, para }) => {
    return (
        <div className='collapse-child'>
            <p className="theme-p">{para}</p>
            <ul className='btn-items'>
                {btns.map((btn, ind) => {
                    return <li><button key={ind} className="theme-btn btn-outline">{btn}</button></li>
                })}
                <li className='end'><button className="theme-btn btn-outline">Get a Quote <ArrowRight/></button></li>
            </ul>
        </div>
    )
}

const ComprehensiveDigitalSolution = () => {
    const comprehensiveSolutionsData = [
        {
            title: <h5 className="theme-h5">01. Brand Identity <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={1} para={`At Creative Verse Studios, we understand the power of a strong brand identity in driving recognition, loyalty, and success for businesses. Our comprehensive brand identity service is designed to help you define, develop, and amplify your brand's unique story and personality. From logo design and visual elements to messaging and tone of voice, we work closely with you to create a cohesive and memorable brand identity that resonates with your target audience. Whether you're a startup looking to make a splash or an established business seeking a refresh, our team of branding experts is here to guide you every step of the way. Partner with us and let's build a brand that leaves a lasting impression.`} btns={['Brand Strategy', 'Brand Guidelines', 'Brand Startup']} />
        },
        {
            title: <h5 className="theme-h5">02. Logo Design <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={2} para={`At Creative Verse Studios, we specialize in creating visually stunning and impactful logos that capture the essence of your brand. Our team of talented designers combines creativity with strategic thinking to craft logos that resonate with your target audience and leave a lasting impression. From startups looking to establish their presence to established businesses seeking a brand refresh, we tailor our approach to meet your unique needs and goals. Whether you're envisioning a minimalist wordmark, an abstract emblem, or a bold illustrative logo, we're here to bring your vision to life. Partner with us and let's create a logo that sets your brand apart in today's competitive marketplace.`} btns={['Graphic Design', 'Visual Identity', 'Logo Concept']} />
        },
        {
            title: <h5 className="theme-h5">03. UI/UX Design <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={3} para={`At Creative Verse Studios, we specialize in creating captivating user interfaces and seamless user experiences that delight and engage audiences. Our UI/UX design services are tailored to meet the unique needs and objectives of your digital projects, whether you're launching a new website, mobile app, or software platform. From user research and wireframing to prototyping and usability testing, our team of designers and developers work collaboratively to ensure every interaction is intuitive, efficient, and visually stunning. Whether you're a startup striving to make a splash or an established brand seeking a digital transformation, we're here to bring your vision to life. Partner with us and let's create user experiences that leave a lasting impression.`} btns={['Visual Design', 'Interaction Design', 'Prototyping', 'Web Design', 'App Design']} />
        },
        {
            title: <h5 className="theme-h5">04. WordPress Development <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={4} para={`At Creative Verse Studios, we specialize in WordPress development, offering a comprehensive range of services to help you harness the full potential of this versatile platform. Whether you're looking to launch a new website, customize an existing theme, or develop a robust e-commerce solution, our team of experienced developers is here to turn your vision into reality. With a focus on creativity, functionality, and user experience, we ensure that every WordPress site we build is not only visually stunning but also optimized for performance and usability. From responsive design and custom plugin development to ongoing maintenance and support, we provide end-to-end solutions that empower you to succeed online. Partner with us and let's take your WordPress website to the next level.`} btns={['Customization', 'Responsive', 'Maintenance', 'E-commerce']} />
        },
        {
            title: <h5 className="theme-h5">05. Shopify Development <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={5} para={`At Creative Verse Studios, we specialize in Shopify development, offering comprehensive solutions to help you build, customize, and optimize your online store. Whether you're launching a new e-commerce business or looking to take your existing store to the next level, our team of experienced developers is here to bring your vision to life. From customizing Shopify themes and developing bespoke features to integrating third-party apps and optimizing for performance, we ensure that your Shopify store not only looks great but also delivers a seamless and engaging shopping experience for your customers. With a focus on usability, scalability, and conversion optimization, we empower you to maximize your online presence and drive sales.`} btns={['Shopify Apps', 'Integration', 'Migration', 'Themes']} />
        },
        {
            title: <h5 className="theme-h5">06. Custom Website Development <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={6} para={`At Creative Verse Studios, we specialize in custom website development, offering tailored solutions to help you establish a powerful online presence and achieve your business goals. Whether you're a startup looking to make a bold entrance or an established brand seeking a digital transformation, our team of experienced developers is here to turn your vision into a reality. From concept to launch, we work closely with you to understand your requirements, objectives, and target audience, ensuring that every aspect of your website is crafted to perfection. Whether you need a sleek and modern corporate website, an interactive e-commerce platform, or a dynamic web application, we have the expertise and creativity to bring your ideas to life.`} btns={['Frontend', 'Backend', 'Responsive', 'Database Development']} />
        },
        {
            title: <h5 className="theme-h5">07. Mobile Application Development <span className="icon"><ArrowDownIcon /></span></h5>,
            content: <CollapseChild key={7} para={`At Creative Verse Studios, we specialize in custom website development, offering tailored solutions to help you establish a powerful online presence and achieve your business goals. Whether you're a startup looking to make a bold entrance or an established brand seeking a digital transformation, our team of experienced developers is here to turn your vision into a reality. From concept to launch, we work closely with you to understand your requirements, objectives, and target audience, ensuring that every aspect of your website is crafted to perfection. Whether you need a sleek and modern corporate website, an interactive e-commerce platform, or a dynamic web application, we have the expertise and creativity to bring your ideas to life.`} btns={['Android', 'IOS', 'Responsive', 'Mobile App Testing']} />
        },
    ]
    const items = comprehensiveSolutionsData.map((data, ind) => ({
        key: ind,
        label: data.title,
        children: data.content,
    }))
    return (
        <section className='comprehensive-solutions'>
            <div className="container">
                <Row className='comprehensive-row'>
                    <Col xl={12} xs={24}>
                        <h6 className="theme-tagline">Elevate Your Online Presence with Our Tailored Services</h6>
                        <h2 className="theme-h2">Our <span className="highlight">Comprehensive Digital</span> Solutions</h2>
                    </Col>
                    <Col xl={10} xs={24}>
                        <p className="theme-p">At Creative Verse Studios, we offer a range of cutting-edge digital solutions designed to propel your business forward in the digital age. From captivating web design and development to strategic digital marketing campaigns, our team of experts is dedicated to delivering results that exceed expectations.</p>
                    </Col>
                </Row>
                <Collapse accordion bordered={false} items={items} ghost />
            </div>
        </section>
    )
}

export default ComprehensiveDigitalSolution
