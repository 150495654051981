import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';

// images
import img1 from '../images/visionary-software-1.png'
import img2 from '../images/visionary-software-2.png'
import img3 from '../images/visionary-software-3.png'
import img4 from '../images/visionary-software-4.png'
import img5 from '../images/visionary-software-5.png'
import img6 from '../images/visionary-software-6.png'


const VisionaryAgency = () => {
    return (
        <section className='visionary-agency'>
            <div className="container">
                <div className="text-center">
                    <h3 className="theme-h3">We Are A <span className="highlight">Visionary</span> Software <span className="highlight">Agency</span> That Breathes <span className="highlight">Life</span> Into Ideas And <span className="highlight">Transforms</span> Them Into <span className="highlight">Extraordinary Realities</span></h3>
                </div>
            </div>
            <Swiper
                centeredSlides={true}
                spaceBetween={0}
                breakpoints={{
                    640: {
                        spaceBetween:0,
                        slidesPerView: 2
                    },
                    768: {
                        spaceBetween:0,
                        slidesPerView: 4
                    },
                    1024: {
                        spaceBetween:0,
                        slidesPerView: 5
                    },
                }}
                modules={[Autoplay]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                loop={true}
                className="mySwiper"
            >
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img1} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img2} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img3} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img4} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img5} alt="" />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="img-wrapper">
                        <img className='w-100' src={img6} alt="" />
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default VisionaryAgency
