import React from 'react'
import parse from 'html-react-parser'
import { Col, Row } from 'antd'

const ServiceFeatures = ({ tagline, title, paragraph, features }) => {
    return (
        <section className='service-features'>
            <div className="container">
                <div className="text-center">
                    <h6 className="theme-tagline">{parse(tagline)}</h6>
                    <h2 className="theme-h2">{parse(title)}</h2>
                    <p className="theme-p">{parse(paragraph)}</p>
                    <div className="features-listing">
                        <Row className='features-row' gutter={[{ xl: 50, xs: 15 }, { xl: 50, xs: 15 }]}>
                            {features.map((item, index) => {
                                return <Col key={index} xl={8} xs={24}>
                                    <div className="feature-item">
                                        <div className="icon">
                                            <img src={item.icon} alt={item.title} />
                                        </div>
                                        <h4 className="theme-h5">{item.title}</h4>
                                        <p className="theme-p">{item.paragraph}</p>

                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                </div>
            </div>
        </section>
        
    )
}

export default ServiceFeatures
